import React, { useState, useEffect } from 'react';
import { auth, onAuthStateChanged, firestore, collection, getDocs } from './firebase-config';
import './SavedMediaFeed.css';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useLocation } from 'react-router-dom';

function SavedMediaFeed() {
  const [userId, setUserId] = useState(null);
  const [savedMedia, setSavedMedia] = useState([]);
  const location = useLocation();
  const eventCode = location.pathname.split('/')[1];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchSavedMedia = async () => {
      if (!userId) return;

      try {
        const mediaPath = `events/${eventCode}/media`;
        const mediaQuery = collection(firestore, mediaPath);
        const querySnapshot = await getDocs(mediaQuery);

        const userSavedMedia = querySnapshot.docs
          .filter((doc) => {
            const data = doc.data();
            return data.savedBy && data.savedBy.includes(userId);
          })
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : null,
          }));

        setSavedMedia(userSavedMedia);
      } catch (error) {
        console.error('Error fetching saved media:', error);
      }
    };

    fetchSavedMedia();
  }, [eventCode, userId]);

  const handleDownloadAll = async () => {
    if (!savedMedia.length) return;

    const zip = new JSZip();
    const folder = zip.folder('SnappoSavedImages');

    // Fetch and add each image to the ZIP file
    for (const media of savedMedia) {
      try {
        const response = await fetch(media.src);
        if (!response.ok) throw new Error('Failed to fetch image');

        const blob = await response.blob();
        folder.file(media.name || `${Date.now()}.jpg`, blob);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }

    // Generate and download the ZIP file
    zip.generateAsync({ type: 'blob' })
      .then((zipFile) => {
        saveAs(zipFile, 'SavedImages.zip');
      })
      .catch((error) => {
        console.error('Error generating ZIP file:', error);
      });
  };
  

  return (
    <div className="saved-media-feed">
      {savedMedia.length > 0 ? (
        <>
          <div className="download-all-container">
            <button onClick={handleDownloadAll} className="download-all-button">
              Download
            </button>
          </div>
          <div className="saved-media-grid">
            {savedMedia.map((media, index) => (
              <div className="saved-media-item" key={index}>
                <img src={media.src} alt={media.name} className="saved-media-content" />
              </div>
            ))}
          </div>
        </>
      ) : (
        <p> No saved media found.</p>
      )}
    </div>
  );
}

export default SavedMediaFeed;
