// SMSForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SMSForm.css';
import { firestore, collection, getDocs } from './firebase-config';
import { useLocation } from 'react-router-dom';

function SMSForm({ phoneNumber, onPhoneNumberChange }) {
  const [message, setMessage] = useState('welcome to snappo!');
  const [guestPhoneNumbers, setGuestPhoneNumbers] = useState([]);
  const location = useLocation();
  const eventCode = location.pathname.split('/')[1];

  // Fetch all guest phone numbers from Firestore
  useEffect(() => {
    const fetchGuestPhoneNumbers = async () => {
      try {
        const phonePath = `events/${eventCode}/guestList`;
        const querySnapshot = await getDocs(collection(firestore, phonePath));
        const phoneNumbers = querySnapshot.docs
          .map((doc) => doc.data().Phone)
          .filter((phone) => phone);

        setGuestPhoneNumbers(phoneNumbers);
      } catch (error) {
        console.error('Error fetching guest phone numbers:', error);
      }
    };

    fetchGuestPhoneNumbers();
  }, [eventCode]);

  useEffect(() => {
    if (guestPhoneNumbers.length > 0) {
      const phoneNumberList = guestPhoneNumbers.join(', ');
      onPhoneNumberChange(phoneNumberList);
    }
  }, [guestPhoneNumbers, onPhoneNumberChange]);
  
  const handleSendSMS = async () => {
    try {
      // Call the Firebase Cloud Function
      const response = await axios.post('https://us-central1-vip-feed-d30d0.cloudfunctions.net/sendSMS', {
        to: phoneNumber,
        body: message,
      });

      if (response.status === 200) {
        alert('SMS sent successfully!');
      } else {
        alert('Failed to send SMS');
      }
    } catch (error) {
      console.error('Error sending SMS:', error);
    }
  };

  return (
    <div className="sms-form-container">
      <h2>Send Message</h2>
        <input
          type="text"
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={(e) => onPhoneNumberChange(e.target.value)}
        />
        <div className="input-container">
        <textarea
          type="text"
          placeholder="Enter message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={2} /* Adjust the number of visible rows */
          style={{ width: '100%' }} /* Make it full width if needed */
        />
        <button onClick={handleSendSMS}>
          <span className="material-icons small-icon">send</span>
        </button>
      </div>
    </div>
  );
}

export default SMSForm;
