import React, { useState, useEffect, useRef } from 'react';
import {  doc, getDoc, setDoc, storage, firestore, ref, uploadBytes, getDownloadURL, collection, addDoc, auth, serverTimestamp, onAuthStateChanged } from './firebase-config';
import './Camera.css';
import { useLocation } from 'react-router-dom';

function Camera({ addMedia, onClose, currentPrompt }) {
  const [file, setFile] = useState(null);
  const [caption, setCaption] = useState('');
  const [previewUrl, setPreviewUrl] = useState(null);
  const [userId, setUserId] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const inputRef = useRef(null);
  const canvasRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const eventCode = location.pathname.split('/')[1];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        setDisplayName(user.displayName);
      } else {
        setUserId(null);
        setDisplayName('');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFile(file);
      setPreviewUrl(fileUrl);

      // Apply filter and render to canvas
      const img = new Image();
      img.src = fileUrl;
      img.onload = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        
        // Apply filters here
        ctx.filter = 'contrast(1.3) brightness(1.1) saturate(1.2) sepia(0.1) hue-rotate(10deg) blur(0.5px)';
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Update preview with filtered image
        const filteredImageUrl = canvas.toDataURL('image/png');
        setPreviewUrl(filteredImageUrl);
      };
    }
  };

  // const handleClearImage = () => {
  //   setFile(null);
  //   setPreviewUrl(null);
  //   inputRef.current.value = null;
  //   setCaption('');
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (file && userId) {
      setLoading(true);

      try {
        const canvas = canvasRef.current;
        canvas.toBlob(async (blob) => {
          const uniqueId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
          const storagePath = `events/${eventCode}/media/${uniqueId}`;
          const storageRef = ref(storage, storagePath);

          await uploadBytes(storageRef, blob);
          const fileUrl = await getDownloadURL(storageRef);

          const newMedia = {
            displayname: displayName,
            caption: caption,
            src: fileUrl,
            timestamp: serverTimestamp(),
            userId: userId,
            type: 'media',
          };
          const docPath = `events/${eventCode}/media`;
          await addDoc(collection(firestore, docPath), newMedia);
          addMedia(newMedia);

        // Check if user profile exists in events/eventCode/profiles
        const profileDocRef = doc(firestore, `events/${eventCode}/profiles`, userId);
        const profileSnapshot = await getDoc(profileDocRef);

        if (!profileSnapshot.exists()) {
          // If no profile exists, set this image as the profile picture
          await setDoc(profileDocRef, {
            photoURL: fileUrl,
          });
          console.log('Profile created with first photo as profile picture:', fileUrl);
        }

          onClose();
        });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      console.error('No file selected or user not logged in.');
    }
  };

  return (
    <div className="camera-modal-overlay">
      <div className="camera-modal-content">
        <button className="media-modal-close" onClick={onClose}>
          <span className="material-icons">close</span>
        </button>

        {previewUrl ? (
          <div className="camera-preview-section">
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
            <div className="image-preview-container">
              <img src={previewUrl} alt="Preview" className="image-preview" />
            </div>
            <textarea
              className="caption-input"
              placeholder="Write a caption..."
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
            />
            <button 
              type="submit" 
              className="media-submit-btn"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? 'Posting...' : 'Post'}
            </button>
          </div>
        ) : (
          <input
            ref={inputRef}
            type="file"
            accept="image/*"
            capture="camera"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        )}
      </div>
    </div>
  );
}

export default Camera;
