import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { firestore, collection, addDoc, query, where, doc, getDocs, deleteDoc, writeBatch } from './firebase-config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './EventHub.css';
import { useLocation } from 'react-router-dom';

function EventHub() {
  const [activeTab, setActiveTab] = useState('sendMessage');
  const [message, setMessage] = useState('');
  const [promptDescription, setPromptDescription] = useState('');
  const [scheduledDate, setScheduledDate] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [SMSsuccessMessage, setSMSSuccessMessage] = useState('');
  const [promptsuccessMessage, setPromptSuccessMessage] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [filterDate, setFilterDate] = useState(null);
  const [guestList, setGuestList] = useState([]);
  const location = useLocation();
  const eventCode = location.pathname.split('/')[1];
  const [newGuest, setNewGuest] = useState({ firstName: '', lastName: '', phoneNumber: '' });
  const [columns] = useState(['First Name', 'Last Name', 'Phone Number']);
  const [searchQueries, setSearchQueries] = useState({});
  const [editedGuestList, setEditedGuestList] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [guestPhoneNumbers, setGuestPhoneNumbers] = useState([]);

  // Handle tab switching
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setError('');
    setSuccessMessage('');
    setPromptSuccessMessage('');
    setSMSSuccessMessage('');
  };

  // Fetch scheduled prompts from Firestore
  const fetchPrompts = useCallback(async () => {
    try {
      const promptsPath = `events/${eventCode}/prompts`;
      const querySnapshot = await getDocs(collection(firestore, promptsPath));
      const promptList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedPrompts = promptList.sort((a, b) => {
      const dateA = a.timestamp?.toDate ? a.timestamp.toDate() : new Date();
      const dateB = b.timestamp?.toDate ? b.timestamp.toDate() : new Date();
      return dateA - dateB; // Ascending order
    });

      setPrompts(sortedPrompts);
    } catch (error) {
      console.error('Error fetching prompts:', error);
    }
  }, [eventCode]);

  // Fetch guest phone numbers from Firestore
  const fetchGuestPhoneNumbers = useCallback(async () => {
    try {
      const docPath = `events/${eventCode}/guestList`;
      const phoneQuery = query(
        collection(firestore, docPath),
        where('Phone', '!=', '')
      );
      const querySnapshot = await getDocs(phoneQuery);
      const phoneNumberList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        phoneNumber: doc.data().Phone,
      }));

      setGuestPhoneNumbers(phoneNumberList);
    } catch (error) {
      console.error('Error fetching guest phone numbers:', error);
    }
  }, [eventCode]);

  const generateShortLink = (recipientId, eventCode) => {
    return `https://app.snappo.io/${eventCode}?token=${recipientId}`;
  };

  const handleSendSMS = async () => {  
    setSMSSuccessMessage('Sending SMS...');
  
    // Loop through recipients and send SMS to each
    for (const guest of guestPhoneNumbers) {
      try {
        const uniqueId = guest.id;
        const shortLink = generateShortLink(uniqueId, eventCode);
        const messageWithLink = `${message} - View your event here: ${shortLink}`;
  
        const response = await axios.post('https://us-central1-vip-feed-d30d0.cloudfunctions.net/sendSMS', {
          to: guest.phoneNumber, // Send to a single recipient
          body: messageWithLink,
        });
  
        if (response.status === 200) {
          setSMSSuccessMessage(`SMS sent successfully!`);
        } else {
          setError(`Failed to send SMS to ${guest.phoneNumber}`);
        }
      } catch (error) {
        console.error(`Error sending SMS to ${guest.phoneNumber}:`, error);
        // Continue the loop even if one request fails
        setError(`Failed to send SMS to ${guest.phoneNumber}. Continuing...`);
      }
    }
  
    // Clear message field after sending
    setMessage('');
  };

  // Handle form submission for prompts
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!promptDescription || !scheduledDate) {
      setError('Please fill in all fields.');
      return;
    }

    setError('');
    setSuccessMessage('');
    setPromptSuccessMessage('');

    try {
      const promptsPath = `events/${eventCode}/prompts`;
      await addDoc(collection(firestore, promptsPath), {
        promptDescription: promptDescription,
        timestamp: new Date(scheduledDate),
        status: 'Pending',
      });

      setPromptSuccessMessage('Prompt scheduled successfully!');
      setPromptDescription('');
      setScheduledDate('');
      fetchPrompts();
    } catch (error) {
      console.error('Error adding prompt:', error);
      setError('Error adding prompt. Please try again.');
    }
  };

  // Fetch guest list data from Firestore
  const fetchGuestList = useCallback(async () => {
    try {
      const docPath = `events/${eventCode}/guestList`;
      const querySnapshot = await getDocs(collection(firestore, docPath));
      const guestListData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          lastName: data['Last Name'] || '', 
          firstName: data['First Name'] || '',
          phoneNumber: data['Phone'] || '',
          id: doc.id,
        };
      });

      // Sort by last name
      const sortedGuestList = guestListData.sort((a, b) => (a.lastName || '').localeCompare(b.lastName || ''));

      setGuestList(sortedGuestList);
      setEditedGuestList(sortedGuestList);
    } catch (error) {
      console.error('Error fetching guest list:', error);
    }
  }, [eventCode]);

  // Filter prompts by selected date
  const filteredPrompts = prompts.filter((prompt) => {
    if (!filterDate) return true; // Show all if no filter date is selected
    const promptDate = new Date(prompt.timestamp.seconds * 1000);
    return (
      promptDate.getFullYear() === filterDate.getFullYear() &&
      promptDate.getMonth() === filterDate.getMonth() &&
      promptDate.getDate() === filterDate.getDate()
    );
  });

  useEffect(() => {
    fetchPrompts();
    fetchGuestList();
    setSearchQueries({});
    fetchGuestPhoneNumbers();
  }, [fetchPrompts, fetchGuestList, fetchGuestPhoneNumbers]);

  const handleEditChange = (e, rowIdx, column) => {
    const updatedGuestList = [...editedGuestList];
    updatedGuestList[rowIdx] = {
      ...updatedGuestList[rowIdx],
      [column]: e.target.value,
    };
    setEditedGuestList(updatedGuestList);
    setIsEdited(true);
    setSuccessMessage('');
    setError('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGuest({ ...newGuest, [name]: value });
    setSuccessMessage('');
    setError('');
  };

  const handleAddGuest = async (e) => {
    e.preventDefault();

    const { firstName, lastName, phoneNumber } = newGuest;
    
    // Map input values to Firestore fields
    const firestoreGuestData = {
      'First Name': firstName,
      'Last Name': lastName,
      Phone: phoneNumber,
    };

    if (!firstName || !lastName || !phoneNumber) {
      setError('Please fill in all fields.');
      return;
    }

    try {
      const docPath = `events/${eventCode}/guestList`;
      
      const phoneQuery = query(
        collection(firestore, docPath),
        where('Phone', '==', phoneNumber)
      );
      const querySnapshot = await getDocs(phoneQuery);
  
      if (!querySnapshot.empty) {
        setError('Guest with this phone number already exists.');
        setSuccessMessage('');
        return;
      }

      await addDoc(collection(firestore, docPath), firestoreGuestData);
      setGuestList([...guestList, firestoreGuestData].sort((a, b) =>
        (a['Last Name'] || '').localeCompare(b['Last Name'] || '')
      ));
      setNewGuest({ firstName: '', lastName: '', phoneNumber: '' });
      setError('');
      setSuccessMessage('Guest added successfully!');
      await fetchGuestList();
    } catch (error) {
      console.error('Error adding guest:', error);
      setError('Error adding guest. Please try again.');
      setSuccessMessage('');
    }
  };

  const handleDeleteGuest = async (guestId) => {
    try {
      const docPath = `events/${eventCode}/guestList/${guestId}`;
      await deleteDoc(doc(firestore, docPath));
  
      // Remove guest from local state
      setGuestList(guestList.filter((guest) => guest.id !== guestId));
      setSuccessMessage('Guest deleted successfully!');
    } catch (error) {
      console.error('Error deleting guest:', error);
      setError('Error deleting guest. Please try again.');
    }
  };

  // Function to delete a specific prompt by id
const handleDeletePrompt = async (promptId, e) => {
  e.preventDefault();

  try {
    // Reference the specific prompt document
    const promptDocRef = doc(firestore, `events/${eventCode}/prompts`, promptId);
    await deleteDoc(promptDocRef);
    console.log(`Prompt with ID ${promptId} deleted successfully.`);
    fetchPrompts();
    setPromptSuccessMessage('Prompt deleted successfully!');
  } catch (error) {
    console.error("Error deleting prompt:", error);
  }
};

  const handleSearchChange = (e, column) => {
    const { value } = e.target;
    setSearchQueries((prevQueries) => ({
      ...prevQueries,
      [column]: value.toLowerCase(),
    }));
  };  

  const filteredGuestList = editedGuestList.filter((guest) => {
    // Extract search queries
    const firstNameQuery = searchQueries['First Name'] || '';
    const lastNameQuery = searchQueries['Last Name'] || '';
    const phoneQuery = searchQueries['Phone Number'] || '';
  
    // Check if the guest matches the search queries
    const lastNameMatch = lastNameQuery
      ? guest.lastName.toLowerCase().includes(lastNameQuery)
      : true;

    const firstNameMatch = firstNameQuery
    ? guest.firstName.toLowerCase().includes(firstNameQuery)
    : true;
  
    const phoneMatch = phoneQuery
      ? guest.phoneNumber.toLowerCase().includes(phoneQuery)
      : true;
  
    return firstNameMatch && lastNameMatch && phoneMatch;
  });

  const handleSaveChanges = async () => {
    try {
      const docPath = `events/${eventCode}/guestList`;
      const batch = writeBatch(firestore);

      editedGuestList.forEach((guest) => {
        if (guest.id) {
          const docRef = doc(firestore, docPath, guest.id);
          batch.update(docRef, {
            'Last Name': guest.lastName,
            'First Name': guest.firstName,
            'Phone': guest.phoneNumber,
          });
        }
      });

      await batch.commit();

      setSuccessMessage('Guest list updated successfully!');
      setError('');
      setIsEdited(false);
      await fetchGuestList(); // Refresh the guest list
    } catch (error) {
      console.error('Error saving changes:', error);
      setError('Error saving changes. Please try again.');
      setSuccessMessage('');
    }
  };

  useEffect(() => {
    setEditedGuestList(guestList);
  }, [guestList]);

  return (
    <div className="event-hub-page">

      {/* Tabs Section */}
      <div className="tabs-itinerary">
        <button
          className={`tab-button-itinerary ${activeTab === 'sendMessage' ? 'active' : ''}`}
          onClick={() => handleTabClick('sendMessage')}
        >
          Send SMS
        </button>
        <button
          className={`tab-button-itinerary ${activeTab === 'newPrompt' ? 'active' : ''}`}
          onClick={() => handleTabClick('newPrompt')}
        >
          Prompts
        </button>
        <button
          className={`tab-button-itinerary ${activeTab === 'guestList' ? 'active' : ''}`}
          onClick={() => handleTabClick('guestList')}
        >
          Guest List
        </button>
      </div>

      {/* Content Section */}
      <div className="tab-content-itinerary">
        {activeTab === 'sendMessage' && (
          <div className="sms-form-container">
            <h2>Send SMS</h2>
            <h3>To: All Recipients</h3>
            <div className="input-container">
              <textarea
                type="text"
                placeholder="Enter message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={2}
                style={{ width: '100%' }}
              />
              <button onClick={handleSendSMS}>
                <span className="material-icons submit-button">send</span>
              </button>
              
            </div>
              {SMSsuccessMessage && <p className="success-message">{SMSsuccessMessage}</p>}
              {error && <p className="error-message">{error}</p>}
          </div>
        )}

        {activeTab === 'newPrompt' && (
          <form onSubmit={handleSubmit} className="upload-prompt-form">
            <h2>Schedule New Prompt</h2>
            <div className="form-group">
              <textarea
                id="promptDescription"
                value={promptDescription}
                onChange={(e) => setPromptDescription(e.target.value)}
                placeholder="Enter prompt description"
              ></textarea>
            </div>
            <div className="form-group">
              <DatePicker
                selected={scheduledDate}
                onChange={(date) => setScheduledDate(date)}
                showTimeSelect
                dateFormat="Pp"
                placeholderText="Select Date"
              />
            </div>

            {error && <p className="error-message">{error}</p>}
            {promptsuccessMessage && <p className="success-message">{promptsuccessMessage}</p>}

            <button type="submit" className="prompt-submit-button">Submit</button>
          
            <table className="prompts-table">
              <thead>
                <tr>
                  <th>Prompt</th>
                  <th>Scheduled Date
                    <div className="date-filter">
                      <DatePicker
                        selected={filterDate}
                        onChange={(date) => setFilterDate(date)}
                        placeholderText="Filter"
                        dateFormat="Pp"
                        className="date-filter-input"
                      />
                      {/* <button onClick={() => setFilterDate(null)} className="clear-filter-button">
                        Clear
                      </button> */}
                    </div>
                  </th>
                  <th>Status</th>
                  <th className="narrow-column"></th>
                </tr>
              </thead>
              <tbody>
                {filteredPrompts.map((prompt) => (
                  <tr key={prompt.id}>
                    <td>{prompt.promptDescription}</td>
                    <td>{new Date(prompt.timestamp.seconds * 1000).toLocaleString()}</td>
                    <td>{prompt.status}</td>
                    <td className="narrow-column">
                      <div className="delete-guest-button-container">
                        <button onClick={(e) => handleDeletePrompt(prompt.id, e)} className="delete-guest-button">
                          <span className="material-icons">delete_outline</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
        </form>
        )}
        {/* {activeTab === 'promptTable' && (

        )} */}
        {activeTab === 'guestList' && (
          <div className="event-hub-page">
            {/* Add Guest Form */}
            <div className="add-guest-form">
              <h2>Add New Guest</h2>
              <form onSubmit={handleAddGuest}>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={newGuest.firstName}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={newGuest.lastName}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={newGuest.phoneNumber}
                  onChange={handleInputChange}
                />
                <button type="add-guest">Submit</button>
              </form>
              {error && <p className="error-message">{error}</p>}
              {successMessage && <p className="success-message">{successMessage}</p>}
            </div>

            {isEdited && (
              <div className="save-changes-container">
                <button onClick={handleSaveChanges} className="save-changes-button">
                    Save Changes
                </button>
              </div>
            )}

            <table className="prompts-table">
              <thead>
                <tr>
                  {columns.map((column, idx) => (
                    <th key={idx}>
                      {column}
                        <input
                          type="text"
                          placeholder={`Search`}
                          value={searchQueries[column] || ''}
                          onChange={(e) => handleSearchChange(e, column)}
                          className="search-input"
                        />
                    </th>
                  ))}
                  <th className="narrow-column"></th>
                </tr>
              </thead>
              <tbody>
                {filteredGuestList.length > 0 ? (
                  filteredGuestList.map((guest, rowIdx) => (
                    <tr key={rowIdx}>
                      <td>
                        <input
                          type="text"
                          value={guest.firstName || ''}
                          onChange={(e) => handleEditChange(e, rowIdx, 'firstName')}
                          className="editable-cell"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={guest.lastName || ''}
                          onChange={(e) => handleEditChange(e, rowIdx, 'lastName')}
                          className="editable-cell"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={guest.phoneNumber || ''}
                          onChange={(e) => handleEditChange(e, rowIdx, 'phoneNumber')}
                          className="editable-cell"
                        />
                      </td>
                      <td className="narrow-column">
                        <div className="delete-guest-button-container">
                          <button onClick={() => handleDeleteGuest(guest.id)} className="delete-guest-button">
                            <span className="material-icons">delete_outline</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={columns.length}>No guest list available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default EventHub;
