import React, { useState } from 'react';
import './FABUploadMedia.css';
import UploadMediaModal from './UploadMediaModal'; // Import the UploadMediaModal component

function FABUploadMedia() {
  const [showModal, setShowModal] = useState(false); // State to track modal visibility
  const [uploadPhoto, setUploadPhoto] = useState(false); // Track if Upload Photo is selected

  // Function to open the modal
  const openModal = () => {
    setShowModal(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Function to handle closing the UploadMedia form
  const closeUploadMedia = () => {
    setUploadPhoto(false);
  };

  // Function to handle file upload (Photo Library)
  const handleFileUpload = () => {
    setUploadPhoto(true); // Show the UploadMedia form
    setShowModal(false); // Close the options modal
  };


  // Function to handle voice note recording
  const handleVoiceNote = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      let audioChunks = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks);
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        audio.play();
        console.log('Voice note recorded:', audioUrl);
      };

      mediaRecorder.start();
      alert('Recording voice note for 5 seconds...');

      setTimeout(() => {
        mediaRecorder.stop();
      }, 5000);
    } catch (err) {
      console.error('Error recording voice note:', err);
      alert('Could not access the microphone.');
    }
    setShowModal(false); // Close the modal when voice note is selected
  };

  return (
    <div>
      {/* FAB Button to open the modal */}
      <button className="fab-main" onClick={openModal}>
        <span className="material-icons">add</span>
      </button>

      {/* Media Options Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            {/* Close Modal Button */}
            <button className="modal-close" onClick={closeModal}>
              <span className="material-icons">close</span>
            </button>

            {/* Media Options */}
            <div className="media-options">
              {/* Photo Upload Option */}
              <button className="media-option" onClick={handleFileUpload}>
                <span className="material-icons">photo_camera</span>
                <p>Upload Media</p>
              </button>

              {/* Voice Note Option */}
              <button className="media-option" onClick={handleVoiceNote}>
                <span className="material-icons">mic</span>
                <p>Voice Note</p>
              </button>
            </div>
          </div>
        </div>
      )}

      {/* UploadMedia Modal */}
      {uploadPhoto && (
        <div className="modal-overlay">
          <div className="modal-content">
            {/* Close UploadMedia Modal Button */}
            <button className="modal-close" onClick={closeUploadMedia}>
              <span className="material-icons">close</span>
            </button>

            {/* UploadMedia Form */}
            <UploadMediaModal addMedia={() => {}} currentPrompt="Some prompt" onClose={closeUploadMedia} />
          </div>
        </div>
      )}
    </div>
  );
}

export default FABUploadMedia;
