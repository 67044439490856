import React, { useState, useEffect } from 'react';
import { firestore, collection, getDocs } from './firebase-config'; // Import Firestore config
import './ItineraryPage.css';
import { useLocation } from 'react-router-dom';

function ItineraryPage() {
  const [activeTab, setActiveTab] = useState('itinerary');
  const [itinerary, setItinerary] = useState([]);
  const [keyPeople, setKeyPeople] = useState([]);
  const [keyLocations, setKeyLocations] = useState([]);
  const [loading, setLoading] = useState(true); // State to show loading
  const location = useLocation();
  const eventCode = location.pathname.split('/')[1];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch key locations data
        const locationsPath = `events/${eventCode}/keyLocations`;
        const locationsRef = collection(firestore, locationsPath);
        const locationsSnapshot = await getDocs(locationsRef);
        const locationsData = locationsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            name: data.name || '',
            address: data.address || '',
            details: data.description || '',
            date: data.date ? new Date(data.date.seconds * 1000) : null,
          };
        });

        const sortedLocations = locationsData.sort((a, b) => {
          if (a.date && b.date) {
            return a.date - b.date;
          }
          return 0;
        });

        setKeyLocations(sortedLocations);

        // Create a map for locations using their IDs
        const locationsMap = locationsData.reduce((map, loc) => {
          map[loc.id] = loc.name;
          return map;
        }, {});

        // Fetch key people data
        const peoplePath = `events/${eventCode}/keyPeople`;
        const peopleRef = collection(firestore, peoplePath);
        const peopleSnapshot = await getDocs(peopleRef);
        const peopleData = peopleSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            name: data.name || '',
            role: data.role || '',
            phone: data.phoneNumber || '',
            email: data.email || '',
          };
        });
        setKeyPeople(peopleData);

        const peopleMap = peopleData.reduce((map, person) => {
          map[person.id] = { name: person.name, phone: person.phone };
          return map;
        }, {});

        // Fetch itinerary data
        const itineraryPath = `events/${eventCode}/itinerary`;
        const itineraryRef = collection(firestore, itineraryPath);
        const itinerarySnapshot = await getDocs(itineraryRef);

        // Create an object to group events by date
        const groupedItinerary = {};

        itinerarySnapshot.docs.forEach((doc) => {
          const data = doc.data();
          const eventDate = data.date ? new Date(data.date.seconds * 1000) : null;

          const formattedDate = eventDate
            ? eventDate.toLocaleDateString('en-US', {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
              })
            : 'No Date';

          const formattedTime = eventDate
            ? eventDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
            : 'No time';

          // Initialize group if it doesn't exist
          if (!groupedItinerary[formattedDate]) {
            groupedItinerary[formattedDate] = [];
          }

          // Add the event to the corresponding date group
          groupedItinerary[formattedDate].push({
            id: doc.id,
            eventName: data.eventName || 'No title',
            eventLocation: locationsMap[data.eventLocation] || 'Unknown Location',
            time: formattedTime,
            contactName: peopleMap[data.contact] ? peopleMap[data.contact].name : '',
            contactPhone: peopleMap[data.contact] ? peopleMap[data.contact].phone : '',
            date: eventDate,
          });
        });

        // Sort events within each date group by time
        for (const date in groupedItinerary) {
          groupedItinerary[date] = groupedItinerary[date].sort((a, b) => {
            if (a.time && b.time) {
              return new Date(`1970-01-01T${a.time}`) - new Date(`1970-01-01T${b.time}`);
            }
            return 0;
          });
        }

        // Convert the grouped object to an array and sort by the date of the group
        const sortedGroupedItinerary = Object.entries(groupedItinerary)
          .sort(([dateA], [dateB]) => {
            const dateObjA = new Date(dateA);
            const dateObjB = new Date(dateB);
            return dateObjA - dateObjB;
          });

        setItinerary(sortedGroupedItinerary);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Stop loading after data fetch
      }
    };

    fetchData();
  }, [eventCode]); // Run only once on component mount

  
  const renderItinerary = () => (
    <div>
      {itinerary.length > 0 ? (
        itinerary.map(([date, events], index) => (
          <div className="day-section" key={index}>
            <h2>{date}</h2>
            <div className="events-container">
              {events.map((event, idx) => (
                <div className="event-card" key={idx}>
                  <div className="event-time">
                    <span className="material-icons-outlined">schedule</span>
                    <p>{event.time}</p>
                    </div>
                  <div className="event-details">
                    <h3>{event.eventName}</h3>
                    <p>
                      <span className="material-icons-outlined">location_on</span>
                      {event.eventLocation}
                    </p>
                    {event.contactName && (
                      <div className="event-contact-info">
                        <p>
                          <span className="material-icons-outlined">contact_phone</span>
                          {event.contactName}
                          {event.contactPhone && (
                            <a href={`tel:${event.contactPhone}`}>{event.contactPhone}</a>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <p>No itinerary data available.</p>
      )}
    </div>
  );

  const renderKeyPeople = () => (
    <div className="key-people-container">
      {keyPeople.map((person, index) => (
        <div className="person-card" key={index}>
          <h3>{person.name}</h3>
          <p><strong>Role:</strong> {person.role}</p>
          <p>
            <span className="material-icons-outlined">contact_phone</span>
            <a href={`tel:${person.phone}`}>{person.phone}</a>
          </p>
          <p>
            <span className="material-icons-outlined">email</span>
            <a href={`mailto:${person.email}`}>{person.email}</a>
          </p>
        </div>
      ))}
    </div>
  );

  const renderKeyLocations = () => (
    <div className="key-locations-container">
      {keyLocations.map((location, index) => (
        <div className="location-card" key={index}>
          <h3>{location.name}</h3>
          {location.address && (
                      <p>
                        <span className="material-icons-outlined">location_on</span>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location.address)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {location.address}
                        </a>
                      </p>
                    )}
          <p>{location.details}</p>
        </div>
      ))}
    </div>
  );

  if (loading) {
    return <p>Loading itinerary...</p>;
  }

  return (
    <div className="itinerary-page">
      <div className="tabs-itinerary">
        <button
          className={`tab-button-itinerary ${activeTab === 'itinerary' ? 'active' : ''}`}
          onClick={() => setActiveTab('itinerary')}
        >
          Itinerary
        </button>
        <button
          className={`tab-button-itinerary ${activeTab === 'people' ? 'active' : ''}`}
          onClick={() => setActiveTab('people')}
        >
          Contacts
        </button>
        <button
          className={`tab-button-itinerary ${activeTab === 'locations' ? 'active' : ''}`}
          onClick={() => setActiveTab('locations')}
        >
          Key Locations
        </button>
      </div>

      {/* Render content based on active tab */}
      <div className="itinerary-content">
        {activeTab === 'itinerary' && renderItinerary()}
        {activeTab === 'people' && renderKeyPeople()}
        {activeTab === 'locations' && renderKeyLocations()}
      </div>
    </div>
  );
}

export default ItineraryPage;
